import React from 'react'
import { Link } from 'gatsby'
import classes from './pager.module.scss'

const Pager = ({ pageContext }) => {
    const { previousPagePath, nextPagePath } = pageContext
    return (
        <>
        <div className={classes.pager}>
            {previousPagePath && <Link to={previousPagePath} className={classes.text}><p className={classes.previous}>Poprzednia strona</p></Link>}
            {nextPagePath && <Link to={nextPagePath} className={classes.text}><p className={classes.next}>Następna strona</p></Link>}
        </div>
        </>
    )
}
export default Pager
 