import React from "react"
import BackgroundImage from "gatsby-background-image"

import classes from "./blog-post.module.scss"
const BlogPost = ({ title, date, description, image }) => {
  const words_limit = 200
  const cutTitle = title => {
    if (title.length > 12) {
      const words = title.split(" ")
      return (
        <>
          {words.map(word => (
            <div className={classes.titleItem}>{word}</div>
          ))}
        </>
      )
    }
    return <div className={classes.titleItem}>{title}</div>
  }
  const cutDescription = description => {
    let re =/[*#\n]+/
    return description.length < words_limit
      ? description.split(re).join(' ')
      : description.split(re).join(' ').substr(0,words_limit) + "..."
  }
  return (
    <div className={classes.blogCard}>
      <div className={classes.imageContainer}>
        <BackgroundImage
        fadeIn={false} loading="eager"
          fluid={image}
          Tag="div"
          className={classes.image}
        ></BackgroundImage>
        <h2 className={classes.postTitle}>{cutTitle(title)}</h2>{" "}
      </div>
      <p className={classes.description}>
        {cutDescription(description)}
      </p>
      <p className={classes.date}>{date}</p>
      <button onClick={() => {}} className={classes.button}>
        Więcej &nbsp; &rarr;
      </button>
    </div>
  )
}

export default BlogPost
