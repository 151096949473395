import React from "react"
import { graphql, Link } from "gatsby"

import BlogPost from "../../components/blog/blog-post/blog-post.js"
import classes from "./blog.module.scss"
import Layout from "../../components/layout"
import Pager from "../../components/blog/pager/pager.js"
import SEO from "../../components/seo.js"
const Blog = ({ data, pageContext }) => {
  return (
    <Layout>
      <SEO title="Blog" description={data.strapiMetaTag.opis_strony} keywords={data.strapiMetaTag.tagi}/>
      <section className={classes.blog}>
        <h1 className={classes.blogTitle}>Mój blog</h1>
        <ul className={classes.blogList}>
          {data.allStrapiWpis.edges.map(post => (
            <li key={post.node.strapiId}>
              <Link
                to={"/blog/post/" + post.node.link}
                className={classes.link}
              >
                <BlogPost
                  key={post.node.strapiId}
                  title={post.node.Tytul}
                  date={post.node.published_at}
                  description={post.node.Tekst}
                  image={post.node.Obraz.childImageSharp.fluid}
                />
              </Link>
            </li>
          ))}
        </ul>
      </section>
      <Pager pageContext={pageContext} />
    </Layout>
  )
}
export default Blog
export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    strapiMetaTag(nazwa_strony: {eq: "blog"}) {
      opis_strony
      tagi
    }
    allStrapiWpis(
      sort: { fields: strapiId, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          strapiId
          id
          link
          Tekst
          Tytul
          published_at(formatString: "DD-MM-YYYY")
          Obraz {
            childImageSharp {
              fluid(maxWidth: 350) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
